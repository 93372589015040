// ============================
//     Table css start
// ============================

.table {
    &.table-align-center {
        td,
        th {
            vertical-align: middle;
        }
    }
    thead th{
        padding: 0.9rem 0.75rem;
    }
}


.table-hover tbody tr:hover {
    background-color: transparentize($primary, 0.97);
}
