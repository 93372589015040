// ============================
//     dropdown css start
// ============================

.dropdown-toggle {
    &.arrow-none {
        &:after {
            display: none;
        }
    }
}

.dropdown-menu {
    padding: 15px 0;
    box-shadow: 0 4px 24px 0 rgba(62, 57, 107, 0.18);
    border: none;
    border-radius: 2px;
}

.dropdown-item {
    &:focus,
    &:hover {

    }
}

.dropdown {
    .dropdown-item {
        padding: 10px 25px;
        i{
            font-size: 18px;
            margin-right: 10px;
        }
        svg {
            width: 18px;
            height: 18px;
            margin-right: 10px;
            fill:#f2f2f2;
        }
    }
}
// dropdown css end
