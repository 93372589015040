// ============================
//     Advance Layout css start
// ============================

.advance-layout {
    background-color: $primary;
    background-image: linear-gradient(to bottom , transparent 250px, $body-bg 0);
    min-height: 100vh;
    @media (min-width: 1025px) {
        .pc-sidebar {
            top: calc(#{$header-height} + 30px);
            bottom: 30px;
            left: 30px;

            .navbar-content {
                height: calc(100vh - 70px - #{$header-height});
            }
        }

        .page-header {
            background: transparent;
            box-shadow: none;
            position: absolute;
            top: 20px;
            left: 0;

            .breadcrumb-item + .breadcrumb-item::before {
                content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='14' height='14' stroke='%23ffffff' stroke-width='2' fill='none' stroke-linecap='round' stroke-linejoin='round' class='css-i6dzq1'%3E%3Cpolyline points='13 17 18 12 13 7'%3E%3C/polyline%3EE%3C/svg%3E");
            }

            .breadcrumb li,
            .breadcrumb a,
            h5 {
                color: #fff;
            }
        }

        .pc-header {
            left: 0;
            z-index: 1026;
            box-shadow: none;
        }

        .pc-container {
            margin-left: calc(#{$sidebar-width} + 30px);

            .pcoded-content {
                padding-top: calc(40px + 55px);
            }
        }
    }
    @media (max-width: 1024px) {
        .pc-header {
            &.dark-header,
            &[class*="bg-"] {
                .pc-head-link {
                    color: $header-color !important;
                }
            }
            .b-brand{
                display: none;
            }
        }
        .pc-container{
            background: $body-bg;
        }
        .pc-sidebar {
            .navbar-content {
                height: 100vh;
            }
        }
    }
    .pct-customizer{
        display: none;
    }
}
// Advance Layout css end
